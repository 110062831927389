import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/css/common.css"
// import "./assets/css/reset.css"
import "./assets/css/reset2.css"

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI)

import commonFun from "./assets/js/commonFun.js"
// // console.log(commonFun)
Vue.prototype.$commonFun=commonFun

Vue.config.productionTip = false
Vue.prototype.$bus=new Vue()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
