<template>
    <div>

    </div>
</template>

<script>
import {getWebsiteSetting} from "@/axios/api"
export default {
    data(){
        return{
            pcPages:[
                {
                    path:"/merchandise",
                }
            ]
        }
    },
    methods: {
        // 查询网站配置
		gainWebsiteSetting(){
			getWebsiteSetting({
				// host:location.host,
				host:"www.i-dian.com",
            }).then(res=>{
                // console.log("setting",res);
                if(res.code==200){
                    var winWidth =  window.innerWidth || window.screen.availWidth||document.body.clientWidth||540;
                    if (winWidth>1920){
                        winWidth = 1920
                    }
                    if(winWidth<1200){
                        location.href=location.protocol+'//'+res.data.h5Host
                    }
                }
            })
        }
    },
    created() {
        this.gainWebsiteSetting();
    },
}
</script>

<style lang="scss" scoped>

</style>