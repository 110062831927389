import axios from 'axios'
// 使用element-ui Message做消息提醒
//1. 创建新的axios实例，
const service = axios.create({
    // 公共接口--这里注意后面会讲
    // baseURL: process.env.BASE_API,
    // withCredentials:true,
    baseURL: _root,
    timeout: 50 * 1000,
})
// axios.defaults.headers.post['Content-Type'] = 'text/plain';
// 2.请求拦截器
service.interceptors.request.use(config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // // console.log(config)
    // return
    // config.data = qs.stringify(config.data); //数据转化,也可以使用qs转换
    config.headers = {
        // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        // "Content-Type": "application/json; charset=UTF-8"
        "Content-Type":"multipart/form-data",
        "Authorization":"Bearer "+localStorage.AOGEYA_loginInfo
    };
    if(config.requestType=='json'){
        config.headers={
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization":"Bearer "+localStorage.AOGEYA_loginInfo
        }
    }
    if(localStorage.AOGEYA_businessId){
        config.headers.businessId=localStorage.AOGEYA_businessId
    }
    if (config.params){
        // sessionStorage.accessToken="dvKLDTvMsyXcwAL4n6%2FaP2za96Aidi4j5%2FYaZsAnYR2cC85cR12Cze8fV1twJ3WmBBtOCZBsurhl%0D%0AeRj99tgsfOC0%2F1iqFjoZ9zZa8JNBZo8%3D"
        // if(sessionStorage.accessToken){
        //     config.params.accessToken=sessionStorage.accessToken
        // }
        config.params.t = new Date().getTime()
    }else{
        var t=new Date().getTime()
        config.params = {
            t:t
        }
        if(config.url.indexOf("/sendSms")!=-1){
            // // console.log(222,config.data)
            config.data.sign=MD5.createMD5String(config.data.telephone+'YDJJ#@!'+t)
            // // console.log(config.data['sign'])
        }
    }
    return config
}, error => {
    Promise.reject(error)
})
// 3.响应拦截器
service.interceptors.response.use((response,text,head) => {
    return response.data
    // // console.log(response)
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
}, error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // // console.log(error.response.status)
        switch (error.response.status) {
            case 300:
                error.message = '错误请求'
                break;
            case 302:
                error.message = '未授权，请重新登录'
                break;
            default:
                error.message = `连接错误${error.response.status}`;
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
        }
        error.message = '网络超时，请稍后再试'
    }

    // Toast(error.message)
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
})
//4.导入文件
export default service